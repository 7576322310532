<template>
  <!-- 目前仅限在线版 -->
  <div style="position: relative;height: calc(100vh - 50px);">
    <div id="cesiumContainer"></div>
    <div class=" panel">
      <div v-if="locationArr.length===0"
           class="line-btn"
           @click="drawPolyline">绘制航线</div>
      <div v-else>
        <div class="l-title">
          <div @click="tab=1;setCenter()"
               :class="tab===1?'active':''">飞行设置</div>
          <div @click="tab=2"
               :class="tab===2?'active':''">航点设置</div>
        </div>
        <div class="line-panel"
             v-show="tab===1">
          <div class="infos">
            <div class="info-item">
              <div class="title">航点数</div>
              <div class="data">{{ locationArr.length }}</div>
            </div>
            <div class="info-item">
              <div class="title">航线长度</div>
              <div class="data">
                {{ ariLineLength }}
              </div>
            </div>
            <div class="info-item">
              <div class="title">预计飞行时间</div>
              <div class="data">{{flytime}}分钟</div>
            </div>
          </div>
          <div class="line-item">
            <h4>航线海拔高度(m)</h4>
            <p style="margin-bottom:5px"><span>在原值上增加:</span><Input v-model="addLineAltNum"
                     type="number"
                     style="width: 140px;"
                     size="small"
                     :number='true'
                     @on-enter='addLineAlt(1)' /></p>
            <p><span>全部修改为:</span><Input v-model="setLineAltNum"
                     type="number"
                     style="width: 140px;margin-left:1em"
                     size="small"
                     :number='true'
                     @on-enter='addLineAlt(2)' /></p>
          </div>
          <div class="line-item">
            <h4><span>飞行速度:</span>{{speed}}m/s
              <Slider :max="15"
                      :min="1"
                      style="width:220px;margin:0 auto"
                      @on-change="countTime"
                      v-model="speed"></Slider>
            </h4>
          </div>
          <div class="line-item">
            <span>任务完成动作</span>
            <Select placeholder
                    v-model="select"
                    size="small">
              <Option v-for="item in finishAction"
                      :value="item.value"
                      :key="item.value">{{ item.label }}</Option>
            </Select>
          </div>
          <div class="line-item">
            <Button type="primary"
                    @click="save">保存</Button>
            <Button @click="$router.go(-2)">返回</Button>
          </div>
        </div>
        <div v-show="tab===2"
             class="point-panel">
          <div class="point-item">
            <!-- <Button @click="">高程设置</Button> -->
          </div>
          <!-- idx为航点索引 -->
          <div v-for="(item,idx) in locationArr"
               :key='idx'
               class="point-item">
            <h4>{{idx===0?'起飞点':'航点'+(idx+1)}}
              <Icon type="ios-paper-plane-outline"
                    style="cursor: pointer;float:right"
                    @click="flyTo(item[1],item[0],item[2])"
                    size="20" />
            </h4>
            <p><span>经度:</span>{{item[0]}}</p>

            <p><span>纬度:</span>{{item[1]}}</p>
            <p><span>海拔高程(m):</span>

              <Input v-model="item[2]"
                     type="number"
                     style="width: 200px;"
                     size="small"
                     :number='true'
                     @on-change='changePoint()' />
            </p>
            <p v-show="idx!==0"><span>相对高度(m):</span><span
                    :style="{color:-200<(Number(item[2] - locationArr[0][2]).toFixed(2))<500?'#fff':'#f00'}">{{Number(item[2] - locationArr[0][2]).toFixed(2)}}</span></p>
            <div>
              <span>云台俯仰角:{{waypointattribute[idx].pith || 0}}°</span>
              <Slider :max="0"
                      :min="-90"
                      show-tip='never'
                      style="width:200px;margin:0 auto"
                      v-model="waypointattribute[idx].pith"></Slider>
            </div>
            <div>
              <span>机头朝向:{{waypointattribute[idx].heading || 0}}°</span>
              <Slider :max="180"
                      :min="-180"
                      show-tip='never'
                      style="width:200px;margin:0 auto"
                      v-model="waypointattribute[idx].heading"></Slider>
            </div>
            <div v-for="(action,index) in waypointactions[idx]"
                 style="margin-top:3px"
                 :key='index'>
              <Select v-model="action.value"
                      :transfer="true"
                      style="width: 120px;margin-right:20px"
                      size="small">
                <Option v-for="active in actionType"
                        :value="active.value"
                        :key="active.value">{{ active.label }}</Option>
              </Select>
              <InputNumber v-if="action.value == 'ROTATE_AIRCRAFT'"
                           controls-outside
                           :max="180"
                           :min="-180"
                           size="small"
                           :active-change="false"
                           style="width: 100px"
                           v-model="action.actionParam"></InputNumber>
              <InputNumber v-else-if="action.value == 'STAY'"
                           controls-outside
                           :max="3"
                           :min="1"
                           size="small"
                           :active-change="false"
                           style="width: 100px"
                           v-model="action.actionParam"></InputNumber>

              <Icon type="md-trash"
                    size="20"
                    style="cursor: pointer;"
                    @click="removeWayAction(idx,index)" />
            </div>
            <div v-show="waypointactions[idx].length<5"
                 class="point-btn"
                 @click="addWayAction(idx)">添加航点动作</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linePng from '@/assets/line.png';

export default {
  data() {
    return {
      locationArr: [], // 航点位置集合
      last_locationArr: [], // 上一次记录的航点位置集合
      line: '',
      tab: 1,
      ariLineLength: 0, // 航线长度
      addLineAltNum: 0, // 航线增加高度
      setLineAltNum: 0, // 航线高度设置值
      speed: 1, // 飞行速度
      linePng,
      waypointactions: [], // 航点动作列表
      waypointattribute: [], // 航点属性列表
      actionType: [
        {
          value: 'START_TAKE_PHOTO',
          label: '拍照',
        },
        {
          value: 'START_RECORD',
          label: '开始录像',
        },
        {
          value: 'STOP_RECORD',
          label: '停止录像',
        },
        {
          value: 'STAY',
          label: '悬停',
        },
        {
          value: 'ROTATE_AIRCRAFT',
          label: '飞机偏航角',
        },
      ],
      select: 1,
      finishAction: [
        {
          label: '自动返航',
          value: 1,
        },
        {
          label: '原地降落',
          value: 2,
        },
        {
          label: '停止在最后一个执行点',
          value: 3,
        },
      ],
      distance: 0, // 航线长度,单位米
      flytime: 0, // 预计飞行事件
    };
  },
  mounted() {
    this.initMap();
    // 加上地图位置搜索功能
    /**
     * 新增模式下只绘制线;查看模式下绘制出不可编辑的线和点
     * 新增模式:画完点后
     * 监听点删除:editRemovePoint	String	编辑删除了点 标绘事件 和点添加事件:drawAddPoint	String	绘制过程中增加了点 标绘事件
     */
  },
  methods: {
    initMap() {
      let that = this;
      console.log(that.$Center);
      let viewer = new mars3d.Map('cesiumContainer', {
        scene: {
          center: {
            lat: that.$Center[1],
            lng: that.$Center[0],
            alt: 10000,
            heading: 0,
            pitch: -45,
          },
          showSun: true,
          showMoon: false,
          showSkyBox: true,
          showSkyAtmosphere: false, //关闭球周边的白色轮廓 map.scene.skyAtmosphere = false
          fog: false,
          fxaa: true,
          globe: {
            // show: false,
            showGroundAtmosphere: false, //关闭大气（球表面白蒙蒙的效果）
            depthTestAgainstTerrain: false,
            baseColor: '#546a53',
          },
          cameraController: {
            zoomFactor: 3.0,
            minimumZoomDistance: 1,
            maximumZoomDistance: 50000000,
            enableRotate: true,
            enableZoom: true,
            // enableCollisionDetection: true, //是否允许 地形相机的碰撞检测  -----对应：进入地下     无效
          },
        },
        basemaps: [
          {
            name: '高德影像',
            icon: 'http://mars3d.cn/img/basemaps/gaode_img.png',
            type: 'group',
            layers: [{ name: '底图', type: 'gaode', layer: 'img_d' }],
            show: true,
          },
          {
            name: '高德影像(含标注)',
            icon: 'http://mars3d.cn/img/basemaps/gaode_img.png',
            type: 'group',
            layers: [
              { name: '底图', type: 'gaode', layer: 'img_d' },
              { name: '注记', type: 'gaode', layer: 'img_z' },
            ],
            // show: true,
          },
          {
            name: '高德电子',
            icon: 'http://mars3d.cn/img/basemaps/gaode_vec.png',
            style: 'darkblue',
            type: 'gaode',
            layer: 'vec',
            // show: true,
          },
        ],
        control: {
          baseLayerPicker: true, //basemaps底图切换按钮,同时展示底图
          homeButton: false, //视角复位按钮
          sceneModePicker: false, //二三维切换按钮
          navigationHelpButton: false, //帮助按钮
          fullscreenButton: false, //全屏按钮
          defaultContextMenu: false, //右键菜单
          mouseDownView: true,
          animation: false,
          geocoder: false,
          //   locationBar: {
          //     fps: true,
          //     enable: true,
          //     crs: 'EPSG4326',
          //   },
        },
        // hasTerrain: false,
        terrain: {
          url: 'http://data.mars3d.cn/terrain',
          show: true,
        },
        // terrain: {
        //   requestVertexNormals: false,
        //   show: true,
        //   type: 'xyz',
        //   url: '//data.mars3d.cn/terrain',
        // },
      });

      window.Viewer_figure = viewer; // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
      window.Viewer_figure.viewer.requestRenderMode = false;

      this.initGraphicLayer();

      let defaultContextmenuItems =
        window.Viewer_figure.getDefaultContextMenu();
      defaultContextmenuItems[0] = {
        text: '显示此处经纬度',

        show: function (e) {
          return Cesium.defined(e.cartesian);
        },
        callback: function (e) {
          let mpt = mars3d.LatLngPoint.fromCartesian(e.cartesian);
          that.$Modal.info({
            title: '当前位置信息',
            content:
              '<p>经度:' +
              mpt._lng +
              '</p><p>纬度:' +
              mpt._lat +
              '</p><p>海拔:' +
              mpt._alt +
              '</p>',
          });
        },
      };
      defaultContextmenuItems.splice(1, 1); // 删除查看视角信息
      defaultContextmenuItems.splice(2, 2); // 删除三维模型和地形服务(三角网)
      defaultContextmenuItems.splice(3, 1); // 删除标注
      //   defaultContextmenuItems[3].children.splice(3, 1); // 圆形标记(因为无法删除)
      defaultContextmenuItems[3].children.splice(6, 10); // 删除泛光、亮度、夜視、黑白、拾取高亮
      defaultContextmenuItems[4].children.splice(0, 1); // 删除深度監測
      defaultContextmenuItems[4].children.splice(2, 1); // 删除开启大气渲染

      window.Viewer_figure.bindContextMenu(defaultContextmenuItems);

      //在layer上绑定右键菜单
      window.Viewer_graphicLayer.bindContextMenu([
        // {
        //   text: '停止编辑',
        //   iconCls: 'fa fa-trash-o',
        //   callback: function (e) {
        //     let graphic = e.graphic;
        //     if (graphic) {
        //       that.graphicLayer.removeGraphic(graphic);
        //     }
        //   },
        // },
        {
          text: '删除对象',
          callback: function (e) {
            let graphic = e.graphic;
            if (graphic) {
              window.Viewer_graphicLayer.removeGraphic(graphic);
            }
          },
        },
        {
          text: '计算长度',
          iconCls: 'fa fa-medium',
          show: function (e) {
            let graphic = e.graphic;
            return graphic.type === 'polyline';
          },
          callback: function (e) {
            let graphic = e.graphic;
            let strDis = mars3d.MeasureUtil.formatDistance(graphic.distance);
            // alert('该对象的长度为:' + strDis);
            that.$Modal.info({
              content: '<p>该对象的长度为' + strDis + '</p>',
            });
          },
        },
      ]);
    },
    initGraphicLayer() {
      let that = this;
      // 绘制标注
      window.Viewer_graphicLayer = new mars3d.layer.GraphicLayer({
        hasEdit: true,
        isAutoEditing: true, //绘制完成后是否自动激活编辑
      });
      window.Viewer_figure.addLayer(window.Viewer_graphicLayer);

      //   // 开始编辑
      //   window.Viewer_graphicLayer.on(mars3d.EventType.editStart, function (e) {
      //     // that.openEditPanel(e.graphic);
      //   });

      // 绘制完成
      window.Viewer_graphicLayer.on(mars3d.EventType.drawCreated, function (e) {
        that.locationArr = e.graphic.getCoordinates(false);

        that.ariLineLength = mars3d.MeasureUtil.formatDistance(
          e.graphic.distance
        );
        that.distance = parseInt(e.graphic.distance);
        that.countTime();

        that.last_locationArr = e.graphic.getCoordinates(false);
        that.locationArr.forEach((element) => {
          that.waypointactions.push([]);
          that.waypointattribute.push({ pith: 0, heading: 0 });
        });
      });
      //   window.Viewer_graphicLayer.on(
      //     mars3d.EventType.drawAddPoint,
      //     function (e) {
      //       console.log('添加了点', e);
      //     }
      //   );
      // 拖动或新增点
      window.Viewer_graphicLayer.on(
        mars3d.EventType.editMovePoint,
        function (e) {
          that.addPoint(e);
        }
      );
      // 删除点
      window.Viewer_graphicLayer.on(
        mars3d.EventType.editRemovePoint,
        function (e) {
          console.log('删除了点', e);

          that.removePoint(e);
        }
      );
      //   // 停止编辑
      //   window.Viewer_graphicLayer.on(
      //     [mars3d.EventType.editStop, mars3d.EventType.removeGraphic],
      //     function () {
      //       console.log('停止编辑');
      //       // that.closeEditPanel();
      //     }
      //   );
      // 删除航线
      window.Viewer_graphicLayer.on(
        mars3d.EventType.removeGraphic,
        function () {
          console.log('删除了对象');
          //   that.closeEditPanel();
          that.waypointactions = [];
          that.locationArr = [];
          that.waypointattribute = [];
          that.last_locationArr = [];
        }
      );
    },
    drawPoint() {
      window.Viewer_graphicLayer.startDraw({
        type: 'point',
        style: {
          pixelSize: 12,
          color: '#3388ff',
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        },
      });
    },
    drawPolyline() {
      window.line = window.Viewer_graphicLayer.startDraw({
        type: 'polyline',
        maxPointNum: 99, //限定最大点数，可以绘制2个点的线，自动结束
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        style: {
          //   color: '#3388ff',
          width: 3,
          clampToGround: false,
          // 动画线材质
          material: mars3d.MaterialUtil.createMaterialProperty(
            mars3d.MaterialType.LineFlow,
            {
              color: '#66bd63',
              repeat: new Cesium.Cartesian2(2.0, 1.0),
              image: require('@/assets/line.png'),
              speed: 25,
            }
          ),
        },
      });
    },
    //
    setCenter() {
      window.line.flyTo();
    },
    // 航点投影线
    Dropline() {
      this.locationArr.forEach((element) => {
        console.log(new Cesium.Cartesian3(113.025869, 28.27917, -187));
        let p1 = mars3d.PointUtil.getRayEarthPosition(
          new Cesium.Cartesian3(element[0], element[1], element[2]),
          new Cesium.HeadingPitchRoll(
            2.7456806270909384,
            -0.012612324742984253,
            0.1954768762233649
          ),
          true
        );
        // let shadowPoint = Cesium.Cartesian3.midpoint(
        //   element[0],
        //   element[1],
        //   new Cesium.Cartesian3()
        // );
        console.log(p1);
      });
    },
    // 点删除
    removePoint(e) {
      this.locationArr = e.graphic.getCoordinates(false);
      this.ariLineLength = mars3d.MeasureUtil.formatDistance(
        e.graphic.distance
      );
      this.distance = parseInt(e.graphic.distance);
      this.countTime();
      let result;
      for (let i = 0; i < this.last_locationArr.length; i++) {
        if (this.last_locationArr[i][0] !== this.locationArr[i][0]) {
          result = i;
          break;
        }
      }
      this.last_locationArr = this.locationArr;
      this.waypointactions.splice(result, 1);
      this.waypointattribute.splice(result, 1);
    },
    // 点增加
    addPoint(e) {
      this.ariLineLength = mars3d.MeasureUtil.formatDistance(
        e.graphic.distance
      );
      this.distance = parseInt(e.graphic.distance);
      this.countTime();
      //   console.log('移动或增加了点', e);
      this.locationArr = e.graphic.getCoordinates(false);
      if (this.locationArr.length === this.last_locationArr.length) {
        // 移动
        this.last_locationArr = this.locationArr.concat();
      } else {
        // 增加
        let result;
        for (let i = 0; i < this.last_locationArr.length; i++) {
          if (this.locationArr[i][0] !== this.last_locationArr[i][0]) {
            result = i;
            break;
          }
        }
        this.last_locationArr = this.locationArr.concat();
        this.waypointactions.splice(result, 0, new Array());
        this.waypointattribute.splice(result, 0, { pith: 0, heading: 0 });
      }
    },
    changePoint() {
      //   window.Viewer_graphicLayer._points = this.locationArr;
      window.line.positions = this.locationArr;
      //   this.Dropline();
    },
    addLineAlt(type) {
      switch (type) {
        case 1: // 增量
          this.locationArr.forEach((element) => {
            element[2] += this.addLineAltNum;
          });
          break;
        case 2: // 修改为
          this.locationArr.forEach((element) => {
            element[2] = this.setLineAltNum;
          });
          break;
        default:
          break;
      }
      this.changePoint();
      this.addLineAltNum = 0;
      this.setLineAltNum = 0;
    },
    // 添加航点动作,idx为航点索引
    addWayAction(idx) {
      this.waypointactions[idx].push({
        value: 'STAY',
        actionParam: 1,
      });
    },
    // 计算预计时间
    countTime() {
      this.flytime = Math.ceil(this.distance / this.speed / 60);
    },
    // 删除航点动作,idx-航点索引,index-动作索引
    removeWayAction(idx, index) {
      this.waypointactions[idx].splice(index, 1);
    },
    flyTo(lat, lng, alt) {
      window.Viewer_figure.setCameraView({
        lat: lat,
        lng: lng,
        alt: alt + 300,
        heading: 0,
        pitch: -90,
      });
    },
    save() {
      //   console.log(this.locationArr);
      //   console.log(this.waypointactions);
      /**
       * 1.判断是否各航点只有一个拍照动作,可在整合数组时进行
       * 2.判断各航点间距是否超过2000米
       * 整和动作数组,坐标属性,属性数组
       */
      let ret = []; // 整合后的数组
      // 遍历航点
      for (let i = 0; i < this.locationArr.length; i++) {
        let actives = [];
        let distance = 0;
        let hasPhoto = false;
        // 航点动作判断
        this.waypointactions[i].forEach((element) => {
          // 遍历航点内的航点动作
          if (element.value === 'STAY') {
            actives.push({
              actionType: 'STAY',
              actionParam: element.actionParam * 1000,
            });
          } else {
            if (hasPhoto && element.value === 'START_TAKE_PHOTO') {
              this.$Message.warning(
                i > 0
                  ? '航点' + (i + 1) + '只能存在一个拍照动作'
                  : '起飞点只能存在一个拍照动作'
              );
              //   ret = [];
              return false;
            } else if (element.value === 'START_TAKE_PHOTO') {
              hasPhoto = true;
            }
            actives.push({
              actionType: element.value,
              actionParam: element.actionParam,
            });
          }
        });
        // 航点间距判断
        if (i > 0) {
          let p1 = mars3d.LatLngPoint.fromArray(this.locationArr[i - 1]),
            p2 = mars3d.LatLngPoint.fromArray(this.locationArr[i]);
          distance = mars3d.MeasureUtil.getSurfaceDistance([p1, p2]);
          //   console.log('distance', distance);
          if (
            Number(this.locationArr[i][2] - this.locationArr[0][2]).toFixed(2) >
            500
          ) {
            this.$Message.warning(
              '航点' + (i + 1) + '和起飞点的相对高差不能超过500米'
            );
            // ret = [];
            return false;
          } else if (
            Number(this.locationArr[i][2] - this.locationArr[0][2]).toFixed(2) <
            -200
          ) {
            this.$Message.warning(
              '航点' + (i + 1) + '和起飞点的相对高差不能超过-200米'
            );
            ret = false;
            return false;
          } else if (distance >= 2000) {
            this.$Message.warning(
              '航点' + i > 0
                ? i
                : '起飞点' + '和航点' + (i + 1) + '的间距不能超过2000米'
            );
            // ret = [];
            return false;
          } else if (distance < 2) {
            this.$Message.warning(
              '航点' + i > 0
                ? i
                : '起飞点' + '和航点' + (i + 1) + '的间距不能少于2米'
            );
            // ret = [];
            return false;
          }
        }
        // console.log(this.waypointattribute);

        // 计算航点海拔高度
        let groundHeight = mars3d.PointUtil.getSurfaceTerrainHeight(
          window.Viewer_figure.scene,
          new Cesium.Cartesian3.fromDegrees(
            this.locationArr[i][0],
            this.locationArr[i][1]
          ),
          {
            asyn: false, //是否异步求准确高度
            callback: function (newHeight) {
              //
              if (newHeight == null) return;
              return newHeight.toFixed(2);
            },
          }
        );
        if (groundHeight > this.locationArr[i][2].toFixed(2) - 5) {
          this.$Message.warning(
            (i > 0 ? '航点' + (i + 1) : '起飞点') +
              '的海拔高度过低!容易引发危险!'
          );

          return false;
        } else
          ret.push({
            order: i,
            coordinate_latitude: this.locationArr[i][1],
            coordinate_longitude: this.locationArr[i][0],
            altitude: this.locationArr[i][2],
            gimbalPitch: this.waypointattribute[i].pith,
            heading: this.waypointattribute[i].heading,
            speed: this.speed,
            waypointActions: actives,
          });
      }
      console.log({
        team_id:
          this.$route.query.team_id == 0
            ? this.user_info.team_id
            : this.$route.query.team_id, // 团队id
        airtype: 3,
        dir_id: this.$route.query.dir_id == -1 ? 0 : this.$route.query.dir_id,
        homelat: this.locationArr[0][1] || 0, //起始纬度
        homelng: this.locationArr[0][0] || 0, //起始经度
        homeAltitude: this.locationArr[0][2],
        filetitle: this.$route.query.taskName || '',
        routedistance: this.ariLineLength || 0, //航线长度
        flighttime: this.flytime || 0, //飞计预计时间
        airrouteset: {
          waypointMissionFinishedAction: this.select.toString() || 'NO_ACTION', //任务完成动作
          setMissionHeight: (0).toString(), //飞行高度
          setMissionSpeed: (this.speed || 0).toString(), //飞行速度
        },
        waypoint: ret,
      });
    },
  },
  beforeDestrioy() {
    window.Viewer_figure.destroy();
    window.Viewer_graphicLayer.destroy(); //销毁
  },
};
</script>

<style lang="scss">
#cesiumContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 340px;
  height: 100%;
  background-color: $black;
  color: $white;
  .line-btn {
    background: #0773ca;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    width: 200px;
    margin: 20px auto;
    cursor: pointer;
  }
  .l-title {
    font-size: 14px;
    color: #999;
    margin: 20px auto;
    width: 300px;
    height: 34px;
    line-height: 34px;
    text-align: center;

    display: flex;
    div {
      width: 148px;
      background: $xf_hui5;
      border: 1px solid $xf_hui5;
      cursor: pointer;
    }
    .active {
      background: #0773ca;
      color: #fff;
      border: 1px solid #0773ca;
    }
  }
  .line-panel {
    .infos {
      border-top: 1px solid #aaa;
      border-bottom: 1px solid #aaa;
      display: flex;
      align-items: center;
      // margin-bottom: 20px;
      .info-item {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        border-right: 1px solid #aaa;
        white-space: nowrap;
        &:last-child {
          border-right: 0;
        }
        .title {
          font-weight: bold;
          font-size: 14px;
        }
        .data {
          font-size: 12px;
          color: #409eff;
        }
      }
    }
    .line-item {
      padding: 10px 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid $xf_hui5;
      > p {
        color: $font_color_1;
        > span {
          margin: 0 5px;
        }
      }
    }
  }
  .point-panel {
    height: 800px;
    overflow-y: auto;
    .point-item {
      padding: 5px 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid $xf_hui5;
      > p {
        color: $font_color_1;
        > span {
          margin: 0 5px;
        }
      }
      .point-btn {
        background: #0773ca;
        color: #fff;
        text-align: center;
        padding: 3px 0;
        width: 100px;
        margin: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>